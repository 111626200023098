import _ from 'lodash';

import {
  PanelBankConfigState,
  PanelBankDiff,
  PanelBankSettings,
} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {getFoundPanelSpecs, getPanelBankDiff} from '../../../util/panelbank';
import {
  PANEL_BANK_CUSTOM_CHARTS_NAME,
  PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
  PANEL_BANK_HIDDEN_SECTION_NAME,
  PANEL_BANK_SYSTEM_NAME,
} from '../../../util/panelbankTypes';
import * as Panels from '../../../util/panels';
import {setInShallowClone} from '../../../util/utility';
import {StateType as ExpectedPanelsState} from '../../expectedPanels/reducer';
import * as PanelTypes from '../panel/types';
import {
  PanelBankSectionConfigNormalized,
  Ref as PanelBankSectionConfigRef,
} from '../panelBankSectionConfig/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import {
  addOrUpdatePanelsToSections,
  immutableAddOrUpdatePanelsToSections,
} from './addOrUpdatePanelsToSections';
import * as Types from './types';

/**
 * Applies immutable updates to each section in a panel bank
 * with the provided `getUpdatedSection` callback.
 *
 * `getUpdatedSection` will provide a reference to the current
 * section config. If updates are needed, return a new copy of
 * the section with the changes applied. Otherwise, return the
 * current section config as-is.
 */
export const updateSections = (
  state: ViewReducerState,
  ref: Types.Ref,
  getUpdatedSection: (
    curSectionConfig: Readonly<PanelBankSectionConfigNormalized>,
    idx: number
  ) => PanelBankSectionConfigNormalized
): [ViewReducerState, ActionType] => {
  // set up new state
  const newState = setInShallowClone(
    state,
    ['parts', 'panel-bank-section-config'],
    Object.assign({}, state.parts['panel-bank-section-config'])
  );

  // track current sections state to allow undo
  const curSectionConfigs: PanelBankSectionConfigNormalized[] = [];

  // iterate over each section
  state.parts[ref.type][ref.id].sectionRefs.forEach((sectionRef, idx) => {
    const curSectionConfig = state.parts[sectionRef.type][sectionRef.id];
    curSectionConfigs.push(curSectionConfig);
    newState.parts[sectionRef.type][sectionRef.id] = getUpdatedSection(
      curSectionConfig,
      idx
    );
  });

  const inverseAction = ActionsInternal.setAllSections(ref, curSectionConfigs);

  return [newState, inverseAction];
};

export const immutableReadyPanelBank = (
  state: ViewReducerState,
  ref: Types.Ref
): ViewReducerState => {
  const panelBankConfig = state.parts[ref.type][ref.id];

  if (panelBankConfig.state === PanelBankConfigState.Ready) {
    return state;
  }

  // Special stuff that happens the first time you initialize the PanelBank
  // Sort sections by name, with these special behaviors:
  //   - Pinned sections are first and don't get sorted alphabetically
  //   - Custom Visualizations is first if found
  //   - System is second to last
  //   - Hidden Panels is last

  // Find the Hidden Panels section
  const hiddenSectionRef = panelBankConfig.sectionRefs.slice(-1)[0];
  const prevPanelBankSections = panelBankConfig.sectionRefs.map(
    sectionRef => state.parts[sectionRef.type][sectionRef.id]
  );
  const legacyCustomVizSectionRefIndex = _.findIndex(prevPanelBankSections, {
    name: PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
  });
  // Find the Custom Visualizations section
  const legacyCustomVizSectionRef =
    legacyCustomVizSectionRefIndex > -1
      ? panelBankConfig.sectionRefs[legacyCustomVizSectionRefIndex]
      : null;

  const pinnedSectionRefs = panelBankConfig.sectionRefs.filter(
    r => state.parts[r.type][r.id].pinned
  );

  // Sort sections alphabetically by name
  const sortedSectionRefs = panelBankConfig.sectionRefs
    .filter(
      r =>
        r !== hiddenSectionRef &&
        r !== legacyCustomVizSectionRef &&
        !state.parts[r.type][r.id].pinned
    )
    .sort((a, b) => {
      const nameA = state.parts[a.type][a.id].name.toLowerCase();
      const nameB = state.parts[b.type][b.id].name.toLowerCase();

      // Ensure system is last
      const systemLower = PANEL_BANK_SYSTEM_NAME.toLowerCase();
      if (nameB === systemLower && nameA !== systemLower) {
        return -1;
      } else if (nameB !== systemLower && nameA === systemLower) {
        return 1;
      }

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

  // Add the Custom Visualizations section at the beginning, and the Hidden Panels section at the end
  const updatedSectionRefs = _.compact(
    pinnedSectionRefs
      .concat(
        legacyCustomVizSectionRef != null ? [legacyCustomVizSectionRef] : []
      )
      .concat(sortedSectionRefs)
      .concat(hiddenSectionRef)
  );

  let newState: ViewReducerState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
        [ref.id]: {
          ...panelBankConfig,
          sectionRefs: updatedSectionRefs,
          state: PanelBankConfigState.Ready,
        },
      },
    },
  };

  // Open the first section by default, if nothing is open.
  if (
    updatedSectionRefs.length > 0 &&
    updatedSectionRefs.every(
      sectionRef => !state.parts[sectionRef.type][sectionRef.id].isOpen
    )
  ) {
    const firstSectionRef = updatedSectionRefs[0];

    newState = {
      ...newState,
      parts: {
        ...newState.parts,
        [firstSectionRef.type]: {
          ...newState.parts[firstSectionRef.type],
          [firstSectionRef.id]: {
            ...newState.parts[firstSectionRef.type][firstSectionRef.id],
            isOpen: true,
          },
        },
      },
    };
  }

  // Open the Custom Charts section if it exists
  const customChartSectionRef = updatedSectionRefs.find(
    a => state.parts[a.type][a.id].name === PANEL_BANK_CUSTOM_CHARTS_NAME
  );

  if (customChartSectionRef != null) {
    newState = {
      ...newState,
      parts: {
        ...newState.parts,
        [customChartSectionRef.type]: {
          ...newState.parts[customChartSectionRef.type],
          [customChartSectionRef.id]: {
            ...newState.parts[customChartSectionRef.type][
              customChartSectionRef.id
            ],
            isOpen: true,
          },
        },
      },
    };
  }

  return newState;
};

/** Processes panel bank config into ready state */
export const readyPanelBank = (draft: ViewReducerState, ref: Types.Ref) => {
  const panelBankConfig = draft.parts[ref.type][ref.id];

  if (panelBankConfig.state === PanelBankConfigState.Ready) {
    return;
  }

  // Special stuff that happens the first time you initialize the PanelBank
  // Sort sections by name, with these special behaviors:
  //   - Pinned sections are first and don't get sorted alphabetically
  //   - Custom Visualizations is first if found
  //   - System is second to last
  //   - Hidden Panels is last

  // Find the Hidden Panels section
  const hiddenSectionRef = panelBankConfig.sectionRefs.slice(-1)[0];
  const prevPanelBankSections = panelBankConfig.sectionRefs.map(
    sectionRef => draft.parts[sectionRef.type][sectionRef.id]
  );
  const legacyCustomVizSectionRefIndex = _.findIndex(prevPanelBankSections, {
    name: PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME,
  });
  // Find the Custom Visualizations section
  const legacyCustomVizSectionRef =
    legacyCustomVizSectionRefIndex > -1
      ? panelBankConfig.sectionRefs[legacyCustomVizSectionRefIndex]
      : null;

  const pinnedSectionRefs = panelBankConfig.sectionRefs.filter(
    r => draft.parts[r.type][r.id].pinned
  );

  // Sort sections alphabetically by name
  const sortedSectionRefs = panelBankConfig.sectionRefs
    .filter(r => r !== hiddenSectionRef && r !== legacyCustomVizSectionRef)
    .filter(r => !draft.parts[r.type][r.id].pinned)
    .sort((a, b) => {
      const nameA = draft.parts[a.type][a.id].name.toLowerCase();
      const nameB = draft.parts[b.type][b.id].name.toLowerCase();

      // Ensure system is last
      const systemLower = PANEL_BANK_SYSTEM_NAME.toLowerCase();
      if (nameB === systemLower && nameA !== systemLower) {
        return -1;
      } else if (nameB !== systemLower && nameA === systemLower) {
        return 1;
      }

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

  // Add the Custom Visualizations section at the beginning, and the Hidden Panels section at the end
  panelBankConfig.sectionRefs = _.compact(
    pinnedSectionRefs
      .concat(
        legacyCustomVizSectionRef != null ? [legacyCustomVizSectionRef] : []
      )
      .concat(sortedSectionRefs)
      .concat(hiddenSectionRef)
  );
  // Open the first section by default, if nothing is open.
  if (
    panelBankConfig.sectionRefs.length > 0 &&
    panelBankConfig.sectionRefs.every(
      sectionRef => !draft.parts[sectionRef.type][sectionRef.id].isOpen
    )
  ) {
    const firstSectionRef = panelBankConfig.sectionRefs[0];
    draft.parts[firstSectionRef.type][firstSectionRef.id].isOpen = true;
  }

  // Open the Custom Charts section if it exists
  const customChartSectionRef = panelBankConfig.sectionRefs.find(
    a => draft.parts[a.type][a.id].name === PANEL_BANK_CUSTOM_CHARTS_NAME
  );
  if (customChartSectionRef != null) {
    draft.parts[customChartSectionRef.type][customChartSectionRef.id].isOpen =
      true;
  }

  panelBankConfig.state = PanelBankConfigState.Ready;
};

export const sortSectionsByPinning = (
  draft: ViewReducerState,
  ref: Types.Ref
) => {
  const panelBankConfig = draft.parts[ref.type][ref.id];
  const unpinnedsections = panelBankConfig.sectionRefs.filter(
    r => !draft.parts[r.type][r.id].pinned
  );
  const pinnedSections = panelBankConfig.sectionRefs.filter(
    r => draft.parts[r.type][r.id].pinned
  );
  const sectionRefsSortedByPinning = pinnedSections.concat(unpinnedsections);
  const isCurrentSectionRefsDifferent = panelBankConfig.sectionRefs.some(
    (sectionRef, i) => sectionRef !== sectionRefsSortedByPinning[i]
  );
  if (isCurrentSectionRefsDifferent) {
    panelBankConfig.sectionRefs = sectionRefsSortedByPinning;
  }
};

export const immutableSortSectionsByPinning = (
  state: ViewReducerState,
  ref: Types.Ref
): ViewReducerState => {
  const panelBankConfig = state.parts[ref.type][ref.id];
  const unpinnedsections = panelBankConfig.sectionRefs.filter(
    r => !state.parts[r.type][r.id].pinned
  );
  const pinnedSections = panelBankConfig.sectionRefs.filter(
    r => state.parts[r.type][r.id].pinned
  );
  const sectionRefsSortedByPinning = pinnedSections.concat(unpinnedsections);
  const isCurrentSectionRefsDifferent = panelBankConfig.sectionRefs.some(
    (sectionRef, i) => sectionRef !== sectionRefsSortedByPinning[i]
  );
  if (isCurrentSectionRefsDifferent) {
    return {
      ...state,
      parts: {
        ...state.parts,
        [ref.type]: {
          ...state.parts[ref.type],
          [ref.id]: {
            ...panelBankConfig,
            sectionRefs: sectionRefsSortedByPinning,
          },
        },
      },
    };
  }
  return state;
};

export const immutableDiffAndInitPanels = (
  state: ViewReducerState,
  ref: Types.Ref,
  expectedPanelSpecs: ExpectedPanelsState,
  shouldAutoGeneratePanels?: boolean,
  shouldInitAsHiddenPanels?: boolean
): {
  state: ViewReducerState;
  // return newly created panel/section refs to allow undo
  newPanelRefs: PanelTypes.Ref[];
  newSectionRefs: PanelBankSectionConfigRef[];
} => {
  // can't diff if there's no expected specs
  if (!expectedPanelSpecs) {
    return {state, newPanelRefs: [], newSectionRefs: []};
  }

  // compute panel bank diff
  const panelBankConfig = state.parts[ref.type][ref.id];
  const panelParts = panelBankConfig.sectionRefs.flatMap(sectionRef => {
    const section = state.parts[sectionRef.type][sectionRef.id];
    return section.panelRefs.map(
      panelRef => state.parts[panelRef.type][panelRef.id]
    );
  });
  const foundPanelSpecs = getFoundPanelSpecs(
    panelParts,
    expectedPanelSpecs.apiAddedSpecs
  );
  const keysInPanels = _.compact(panelParts.map(panel => Panels.getKey(panel)));
  const panelBankDiff = getPanelBankDiff(
    expectedPanelSpecs,
    foundPanelSpecs,
    keysInPanels,
    panelBankConfig.state,
    shouldAutoGeneratePanels
  );

  if (!Object.keys(panelBankDiff).length) {
    return {state, newPanelRefs: [], newSectionRefs: []};
  }

  if (shouldInitAsHiddenPanels) {
    Object.values(panelBankDiff).forEach(operation => {
      if (operation.type === 'add') {
        operation.spec = {
          ...operation.spec,
          defaultSection: PANEL_BANK_HIDDEN_SECTION_NAME,
        };
      }
    });
  }

  const {newParts, newPanelRefs, newSectionRefs} =
    immutableAddOrUpdatePanelsToSections(panelBankDiff, state.parts, ref);

  let newState = {
    ...state,
    parts: newParts,
  };

  newState = immutableReadyPanelBank(newState, ref);
  newState = immutableSortSectionsByPinning(newState, ref);

  return {state: newState, newPanelRefs, newSectionRefs};
};

/**
 * @deprecated Use immutableDiffAndInitPanels() instead.
 */
export const diffAndInitPanels = (
  state: ViewReducerState,
  ref: Types.Ref,
  expectedPanelSpecs: ExpectedPanelsState,
  shouldAutoGeneratePanels?: boolean,
  shouldInitAsHiddenPanels?: boolean
) => {
  // can't diff if there's no expected specs
  if (!expectedPanelSpecs) {
    return;
  }

  // compute panel bank diff
  const panelBankConfig = state.parts[ref.type][ref.id];
  const panelParts = panelBankConfig.sectionRefs.flatMap(sectionRef => {
    const section = state.parts[sectionRef.type][sectionRef.id];
    return section.panelRefs.map(
      panelRef => state.parts[panelRef.type][panelRef.id]
    );
  });
  const foundPanelSpecs = getFoundPanelSpecs(
    panelParts,
    expectedPanelSpecs.apiAddedSpecs
  );
  const keysInPanels = _.compact(panelParts.map(panel => Panels.getKey(panel)));
  const panelBankDiff = getPanelBankDiff(
    expectedPanelSpecs,
    foundPanelSpecs,
    keysInPanels,
    panelBankConfig.state,
    shouldAutoGeneratePanels
  );

  if (!Object.keys(panelBankDiff).length) {
    return;
  }

  if (shouldInitAsHiddenPanels) {
    Object.values(panelBankDiff).forEach(operation => {
      if (operation.type === 'add') {
        operation.spec = {
          ...operation.spec,
          defaultSection: PANEL_BANK_HIDDEN_SECTION_NAME,
        };
      }
    });
  }

  // TODO(perf): make immutable so we can move off of immer
  const undoPanelAutoGenInfo = addOrUpdatePanelsToSections(
    panelBankDiff,
    state.parts,
    ref
  );
  readyPanelBank(state, ref);
  sortSectionsByPinning(state, ref);
  return undoPanelAutoGenInfo;
};

const isPanelBankEmpty = (state: ViewReducerState, ref: Types.Ref) => {
  const {sectionRefs} = state.parts[ref.type][ref.id];
  if (!sectionRefs.length) {
    return true;
  }
  if (sectionRefs.length === 1) {
    // 'Hidden Panels' section doesn't count
    const sectionRef = sectionRefs[0];
    const section = state.parts[sectionRef.type][sectionRef.id];
    return section.name === PANEL_BANK_HIDDEN_SECTION_NAME;
  }
  return false;
};

export const addPanelsBySpec = (
  prevState: ViewReducerState,
  ref: Types.Ref,
  specs: Types.KeySpecList
): [ViewReducerState, ActionType] => {
  // Create new state with new panels and sections
  const diff = specs.reduce((acc, {key, spec}) => {
    acc[key] = {type: 'add', spec};
    return acc;
  }, {} as PanelBankDiff);
  const {newParts, newPanelRefs, newSectionRefs} =
    immutableAddOrUpdatePanelsToSections(diff, prevState.parts, ref);
  let newState = {...prevState, parts: {...newParts}};

  // Apply special first-time processing if adding to an empty panel bank
  if (isPanelBankEmpty(prevState, ref)) {
    newState = setInShallowClone(
      newState,
      ['parts', ref.type, ref.id, 'state'],
      PanelBankConfigState.Init
    );
  }
  newState = immutableReadyPanelBank(newState, ref);

  // Respect pinned sections
  newState = immutableSortSectionsByPinning(newState, ref);

  // Set up undo action
  const inverseAction = Actions.addPanelsBySpecUndo(
    ref,
    newPanelRefs,
    newSectionRefs,
    specs
  );
  return [newState, inverseAction];
};

export const updateAllLinePlotSectionSettings = (
  state: ViewReducerState,
  ref: Types.Ref,
  settings: Partial<LinePlotSettings> | undefined
): [ViewReducerState, ActionType] => {
  const newState = setInShallowClone(
    state,
    ['parts', 'panel-bank-section-config'],
    Object.assign({}, state.parts['panel-bank-section-config'])
  );

  const prevSectionConfigs: PanelBankSectionConfigNormalized[] = [];

  state.parts[ref.type][ref.id].sectionRefs.forEach((sectionRef, idx) => {
    const section = state.parts[sectionRef.type][sectionRef.id];
    prevSectionConfigs.push(section);

    newState.parts[sectionRef.type][sectionRef.id] = {
      ...newState.parts[sectionRef.type][sectionRef.id],
      sectionSettings: {
        ...newState.parts[sectionRef.type][sectionRef.id].sectionSettings,
        linePlot: {
          ...(newState.parts[sectionRef.type][sectionRef.id].sectionSettings
            ?.linePlot ?? {}),
          ...settings,
        },
      },
    };
  });

  const inverseAction = ActionsInternal.setAllSections(ref, prevSectionConfigs);

  return [newState, inverseAction];
};

export const updateSettings = (
  state: ViewReducerState,
  ref: Types.Ref,
  settings: Partial<PanelBankSettings>
): [ViewReducerState, ActionType] => {
  const prev = state.parts[ref.type][ref.id].settings;

  const newState = setInShallowClone(
    state,
    ['parts', ref.type, ref.id, 'settings'],
    {
      ...prev,
      ...settings,
    }
  );

  const inverseAction = Actions.updateSettings(ref, prev);

  return [newState, inverseAction];
};
